import Typography from "@/components/Typography";
import Card from "@/components/Card";
import { RedirectIcon } from "@/components/Icon";
import Button from "@/components/Button";
import styles from "./BlogCard.module.scss";

const BlogCard: React.FC<{
  onClick: () => void;
  title: string;
  backgroundUrl: string;
}> = ({ onClick, title, backgroundUrl }) => (
  <Card
    className={styles.blogCard}
    style={{
      background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${backgroundUrl})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}
  >
    <Typography variant="body1" color="text.contrast">
      {title}
    </Typography>
    <Button
      endIcon={<RedirectIcon className={styles.redirectIcon} />}
      variant="text"
      color="inherit"
      className={styles.redirectButton}
      onClick={onClick}
    >
      Read More
    </Button>
  </Card>
);

export default BlogCard;
