import { useResponsive, useSnackbar, useTrackers } from "@/hooks";
import Button from "../Button";
import Dialog, { DialogContent, DialogTitle } from "../Dialog";
import Flag from "../Flag";
import { DownloadIcon } from "../Icon";
import Typography from "../Typography";
import styles from "./ShareWithSocialDialog.module.scss";
import cx from "classnames";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import { useCallback, useState } from "react";
import { CircularProgress, Skeleton } from "@/components/Loader";

function pad(input: number) {
  let stringInput = input.toString();
  while (stringInput.length < 2) stringInput = "0" + stringInput;
  return stringInput;
}

const ShareWithSocial: React.FC<{
  onClose?: () => void;
  startDate: number;
  dateType: string;
  hasDate: boolean;
  imageUrl: string;
  isLoading?: boolean;
  division: string;
  destination: string;
  countryCode: string;
}> = ({
  onClose,
  startDate,
  dateType,
  hasDate,
  imageUrl,
  isLoading = false,
  division,
  destination,
  countryCode,
}) => {
  const { isMobile } = useResponsive();
  const { enqueueSnackbar } = useSnackbar();
  const { track } = useTrackers();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSharing, setIsSharing] = useState(false);

  const date = pad(startDate);
  const isArrived = hasDate && Boolean(startDate <= 0);

  const canShare = Boolean(window.navigator?.share);

  const handleCaptureClick = useCallback(async () => {
    const element = document.querySelector<HTMLElement>(".share-with-social");
    if (!element) return;
    try {
      setIsDownloading(true);
      const canvas = await html2canvas(element, {
        useCORS: true,
        allowTaint: true,
        windowWidth: 1000,
        windowHeight: 1000,
        scale: 5,
      });
      const dataURL = canvas.toDataURL("image/png");
      downloadjs(dataURL, "share-the-news.png", "image/png");
      track("Share The Fun Cover Downloaded", {
        eventId: "share-the-fun-cover-downloaded",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Sorry, could not download the image.", {
        variant: "error",
      });
    } finally {
      setIsDownloading(false);
    }
  }, []);

  const handleShareClick = useCallback(async () => {
    const element = document.querySelector<HTMLElement>(".share-with-social");
    if (!element) return;
    try {
      setIsSharing(true);
      const canvas = await html2canvas(element, {
        useCORS: true,
        allowTaint: true,
        windowWidth: 1000,
        windowHeight: 1000,
        scale: 5,
      });
      canvas.toBlob(async (blob) => {
        if (blob) {
          const file = new File([blob], "share-the-news.jpeg", {
            type: "image/jpeg",
          });
          if (canShare) {
            await window.navigator?.share({
              title: "Share Your Journey",
              text: "Share this moments with friends!",
              files: [file],
            });
          }
        }
      });
      track("Share The Fun Cover Shared", {
        eventId: "share-the-fun-cover-shared",
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Sorry, could not share the image.", {
        variant: "error",
      });
    } finally {
      setIsSharing(false);
    }
  }, []);

  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{ className: styles.dialogPaper }}
    >
      <DialogContent className={styles.dialogContent}>
        {isMobile && (
          <DialogTitle
            onClose={onClose}
            hasCloseButton={Boolean(onClose)}
          ></DialogTitle>
        )}
        {isLoading ? (
          <Skeleton className={styles.bannerContainer} variant="rectangular" />
        ) : (
          <div
            className={cx(styles.bannerContainer, "share-with-social")}
            style={{
              backgroundImage: `url(/_next/image?url=${imageUrl}&w=3840&q=100)`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className={styles.overlay}>
              <div>
                {dateType === "day" && !isArrived && hasDate && (
                  <Typography align="center" variant="body1">
                    The countdown is on...
                  </Typography>
                )}
                {!isArrived && hasDate && (
                  <Typography align="center" variant="body1">
                    I&apos;m heading to
                  </Typography>
                )}
                {isArrived && (
                  <Typography align="center" variant="body1">
                    I have arrived in
                  </Typography>
                )}

                {!hasDate && (
                  <Typography align="center" variant="body1">
                    Pop the champagne
                    <br />
                    because I&apos;m off to
                  </Typography>
                )}
              </div>
              <div className={styles.destination}>
                {destination}
                <Flag
                  countryCode={countryCode}
                  size="36px"
                  className={styles.flag}
                />
              </div>
              {hasDate && Boolean(startDate > 0) && (
                <>
                  <Typography
                    align="center"
                    variant="body1"
                    className={styles.startsIn}
                  >
                    in
                  </Typography>
                  <div className={styles.countdownContainer}>
                    <span className={styles.countdown}>
                      <Typography variant="h6">{date[0]}</Typography>
                    </span>
                    <span className={styles.countdown}>
                      <Typography variant="h6">{date[1]}</Typography>
                    </span>
                  </div>
                  <Typography variant="body1">
                    {dateType}
                    {startDate > 1 ? "s!" : "!"}
                  </Typography>
                </>
              )}
              {!hasDate && (
                <Typography
                  align="center"
                  variant="h6"
                  className={styles.started}
                >
                  soon!
                </Typography>
              )}
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src="/logo-white.svg"
                height="16px"
                width="54px"
                alt="l"
                className={styles.globalLogo}
              />
            </div>
          </div>
        )}
        <Typography align="center" variant="h6" className={styles.title}>
          Share this moment <br />
          with your friends!
        </Typography>
        <div className={styles.actions}>
          <Button
            variant="outlined"
            startIcon={
              isDownloading ? (
                <CircularProgress color="inherit" />
              ) : (
                <DownloadIcon />
              )
            }
            onClick={() => handleCaptureClick()}
            disabled={isLoading || isDownloading}
          >
            {isDownloading ? "Downloading..." : "Download"}
          </Button>
          {/* {canShare && (
            <Button
              variant="outlined"
              // startIcon={
              //   isSharing ? (
              //     <CircularProgress color="inherit" />
              //   ) : (
              //     <DownloadIcon />
              //   )
              // }
              className={styles.shareButton}
              onClick={() => handleShareClick()}
              disabled={isLoading || isSharing}
            >
              {isSharing ? "Sharing..." : "Share"}
            </Button>
          )} */}
        </div>
        <div className={styles.logosContainer}>
          <span className={cx(styles.socialLogo, styles.facebook)} />
          <span className={cx(styles.socialLogo, styles.instagram)} />
          <span className={cx(styles.socialLogo, styles.twitter)} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ShareWithSocial;
