import { useRef } from "react";
import cx from "classnames";
import {
  useBrowserSettings,
  useCurrentTrip,
  useDialog,
  useOpenExternalURL,
  useTripBannerImage,
  useDivisionBannerImage,
} from "@/hooks";
import {
  useTripDetailsQuery,
  useWeatherQuery,
  useDetailsTripQuery,
} from "@/fetch/gworld";
import Card from "@/components/Card";
import Image from "next/image";
// @ts-ignore
import { Image as CloudinaryImage, Transformation } from "cloudinary-react";
import Typography from "@/components/Typography";
import DataTable from "@/components/DataTable";
import dayjs from "dayjs";
import { Skeleton } from "@/components/Loader";
import Flag from "@/components/Flag";
import styles from "./TripCard.module.scss";
import { useResponsive, useTrackers, useLock } from "@/hooks";
import ShareWithSocial from "./ShareWithSocialDialog";
import Button from "../Button";
import { getCloudinaryEnv } from "@/theme/cloudinary";

const DashboardTripCard = () => {
  const { currentTrip: trip } = useCurrentTrip();
  const { data: tripDetails, isLoading: isLoadingTrip } = useTripDetailsQuery(
    trip?.id
  );
  const { data: detailsTrip, isLoading: isLoadingDetailsTrip } =
    useDetailsTripQuery(trip?.id);
  const { data: weather, isLoading: isLoadingWeather } = useWeatherQuery(
    trip?.id
  );
  const { imageUrl, isLoading: isLoadingBanner } = useTripBannerImage(trip);
  const { track } = useTrackers();
  const { isSuperliteTrip } = useLock();
  const { currentBannerImage } = useDivisionBannerImage();
  const departureDate =
    tripDetails?.data?.significantDates.trip_start ??
    tripDetails?.data?.significantDates.arrival_date ??
    tripDetails?.data?.significantDates.tentative_departure;
  const departFormat =
    tripDetails?.data?.significantDates?.trip_start ||
    tripDetails?.data?.significantDates?.arrival_date
      ? "DD MMMM YYYY"
      : "MMMM YYYY";

  const { isMobile } = useResponsive();
  const { isGapp, isGappAndroid } = useBrowserSettings();
  const openExternalURL = useOpenExternalURL();
  const cardRef = useRef<HTMLDivElement | null>(null);

  const cardWidth = cardRef.current?.offsetWidth || 660;

  const {
    onClose: onDialogClose,
    openDialog: openShareWithSocialDialog,
    isOpen: isShareWhitSocialDialogOpen,
  } = useDialog("share-with-community");

  const departureDateToShow = !departureDate
    ? "N/A"
    : dayjs(departureDate.date * 1000)
        .utc()
        .startOf("day")
        .format(departFormat);

  const time = dayjs().tz(weather?.timezone).format("HH:mm");

  const handleClickShare = () => {
    track("Share The Fun Cover Clicked", {
      eventId: "share-the-fun-cover-clicked",
    });

    if (isGapp) {
      const url = `${
        window.location.origin
      }/share-with-community?dateType=${deltaType}&division=${division}&destination=${destination}&startDate=${startDateNumber}&hasDate=${Boolean(
        departureDate
      )}&countryCode=${weather?.countryCode || ""}&imageUrl=${imageUrl || ""}`;
      if (isGappAndroid) {
        window.location.href = url;
      } else {
        openExternalURL(url);
      }
    } else {
      openShareWithSocialDialog();
    }
  };

  const now = dayjs();
  const date = dayjs((departureDate?.date || 0) * 1000);
  const delta = date.diff(now, "month", true);
  let deltaType = "day";
  if (delta > 1 && delta <= 3) {
    deltaType = "week";
  }
  if (delta > 3) {
    deltaType = "month";
  }

  const startDateNumber = date.diff(now, deltaType as any);

  const division = tripDetails?.division || "";
  const destination = tripDetails?.destination || "";
  const isRemoteDestination = tripDetails?.destination === "Remote";

  const isLoading = isRemoteDestination
    ? isLoadingDetailsTrip || isLoadingBanner || isLoadingTrip || !trip
    : isLoadingDetailsTrip ||
      isLoadingBanner ||
      isLoadingTrip ||
      !trip ||
      isLoadingWeather;

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Skeleton height={180} variant="rectangular" />
      ) : (
        <Card className={styles.infoCard} ref={cardRef}>
          <div className={styles.info}>
            <Typography variant="h6">
              {trip?.division} {isRemoteDestination ? "" : "in"}{" "}
              {!isSuperliteTrip && (
                <>
                  {isRemoteDestination ? "" : trip?.destination}{" "}
                  {!isRemoteDestination && (
                    <Flag countryCode={weather?.countryCode} />
                  )}
                </>
              )}
            </Typography>

            {isSuperliteTrip && (
              <div className={styles.superliteDestinationCountry}>
                <Typography className={styles.superLiteCountryText}>
                  {isRemoteDestination ? "" : trip?.destination}{" "}
                </Typography>
                {!isRemoteDestination && (
                  <Flag
                    countryCode={weather?.countryCode}
                    className={styles.superliteCountryIcon}
                  />
                )}
              </div>
            )}
            {!isSuperliteTrip && (
              <DataTable
                rows={[
                  {
                    start: departureDateToShow,
                    duration: tripDetails?.duration || "N/A",
                    status: tripDetails?.status,
                  },
                ]}
                columns={[
                  { headerName: "Starting From", selector: (row) => row.start },
                  { headerName: "Duration", selector: (row) => row.duration },
                  { headerName: "Status", selector: (row) => row.status },
                ]}
              />
            )}
            <Card
              outlined
              className={cx(styles.share, {
                [styles.shareSuperlite]: isSuperliteTrip,
              })}
            >
              {Boolean(startDateNumber > 0) && (
                <Typography variant="subtitle2">
                  Departs in{" "}
                  <span>{`${startDateNumber} ${deltaType}${
                    startDateNumber > 1 ? "s" : ""
                  }`}</span>{" "}
                </Typography>
              )}
              <Button size="small" variant="text" onClick={handleClickShare}>
                Share
              </Button>
            </Card>
            {!isRemoteDestination && (
              <Typography
                component="div"
                variant="body2"
                className={styles.weather}
              >
                <div>
                  {weather?.city}
                  {!isMobile && `, ${weather?.country}`}
                </div>
                <div>
                  {weather?.forecasts?.[0].tempAvg}°C <time>{time}</time>
                </div>
              </Typography>
            )}
          </div>
          {/* {imageUrl && (
            <Image
              src={imageUrl}
              className={styles.bannerImage}
              objectFit="cover"
              alt="banner"
              layout="fill"
            />
          )} */}
          {isSuperliteTrip ? (
            <Image
              src={
                "https://gwatco-res.cloudinary.com/image/upload/f_auto,q_auto,ar_100:30,w_650,c_fill,g_auto,dpr_2.0/gwat.gworld/assets/superlite.jpg"
              }
              className={styles.bannerImage}
              objectFit="cover"
              alt="banner"
              layout="fill"
            />
          ) : (
            <Image
              src={currentBannerImage}
              className={styles.bannerImage}
              objectFit="cover"
              alt="banner"
              layout="fill"
            />
          )}
          {/* {detailsTrip?.banner_image_public_id ? (
            <CloudinaryImage
              cloudName={getCloudinaryEnv()} // Changed to become env based
              publicId={detailsTrip?.banner_image_public_id}
              alt="banner_image"
              className={styles.bannerImage}
            >
              <Transformation
                width={`${cardWidth}`}
                height="180"
                crop="thumb"
                gravity="auto"
              />
            </CloudinaryImage>
          ) : ( */}

          {/* )} */}
        </Card>
      )}
      {isShareWhitSocialDialogOpen && (
        <ShareWithSocial
          onClose={() => onDialogClose()}
          dateType={deltaType}
          startDate={startDateNumber}
          hasDate={Boolean(departureDate)}
          countryCode={weather?.countryCode || ""}
          destination={destination}
          division={division}
          imageUrl={imageUrl || ""}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default DashboardTripCard;
