import styles from "./TaskCard.module.scss";
import { ClickableCard } from "@/components/Card";
import cx from "classnames";
import { Task } from "@/fetch/progress";
import { ChevronIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import { useRouter } from "next/router";
import { useTaskAction, TaskIntentSchema } from "@/hooks";

const TaskCard: React.FC<{ task: Task }> = ({ task }) => {
  const { push } = useRouter();
  const { translateTaskIntent } = useTaskAction();
  const { path, image, onClick } = translateTaskIntent({
    intent: task.route,
    data: task.data,
  } as TaskIntentSchema);

  return (
    <ClickableCard
      className={styles.card}
      onClick={() => {
        onClick?.();
        if (path) push(path);
      }}
    >
      <div className={cx(styles.text, { [styles.center]: !task.body })}>
        <Typography variant="h6">{task.title}</Typography>
        {task.body && (
          <div className={styles.row}>
            <Typography variant="body2">{task.body}</Typography>
            <ChevronIcon fontSize="small" />
          </div>
        )}
      </div>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      {image && <img src={`/images/${image}`} alt="icon" />}
    </ClickableCard>
  );
};

export default TaskCard;
