import { useCourseProgressesQuery } from "@/fetch/academy";
import { CourseProgressCard } from "@/components/Academy";
import { useItemsQuery, SortValues } from "@/fetch/marketplace";
import styles from "./NoTasks.module.scss";
import { ChevronIcon } from "@/components/Icon";
import Typography from "@/components/Typography";
import cx from "classnames";
import Link from "next/link";
import {
  useSwiperSlidesPerView,
  useMarketplaceRegions,
  useResponsive,
  useOpenExternalURL,
} from "@/hooks";
import { Item } from "@/components/Marketplace";
import Swiper, { Slider } from "@/components/Swiper";
import { useEffect, useRef, useState } from "react";
import SwiperType from "swiper/types/swiper-class";
import Button from "@/components/Button";
import BlogCard from "@/components/BlogCard";
import TaskCard from "./TaskCard";

const NoTasks = () => {
  const openExternalURL = useOpenExternalURL();
  const { data: courseProgressesData, isLoading: isCourseProgressesLoading } =
    useCourseProgressesQuery();

  const courseProgresses = courseProgressesData?.response.results || [];

  const { defaultRegion } = useMarketplaceRegions();

  const { data: newDeals, isLoading: isLoadingNewItems } = useItemsQuery({
    pageNum: 1,
    region: defaultRegion?.value,
    pageSize: 3,
    sort: SortValues.NewestDeals,
  });
  const deals = newDeals?.results || [];

  const { isMobile } = useResponsive();
  const containerRef = useRef<HTMLDivElement>(null);
  const { slidesPerView: dealSlidesPerView } = useSwiperSlidesPerView({
    containerRef,
    slideWidth: isMobile ? 200 : 240,
  });

  const [swiperObj, setSwiperObj] = useState<SwiperType | undefined>(undefined);

  useEffect(() => {
    swiperObj?.slideTo(0);
  }, [swiperObj, isLoadingNewItems]);

  return (
    <div className={styles.root}>
      {courseProgresses.length > 0 && (
        <div className={styles.row}>
          <div className={styles.title}>
            <Typography variant="h6" color="text.secondary">
              Ready for your next lesson?
            </Typography>

            <Link href="/academy" passHref>
              <Typography
                variant="subtitle1"
                className={cx(styles.link, styles.view)}
              >
                View All
                <ChevronIcon fontSize="small" />
              </Typography>
            </Link>
          </div>
          {courseProgresses.map((course) => (
            <CourseProgressCard
              key={course.id}
              course={course}
              className={styles.course}
            />
          ))}
        </div>
      )}

      {deals.length > 0 && (
        <div className={styles.row} ref={containerRef}>
          {!isMobile && (
            <div className={styles.title}>
              <Typography variant="h6" color="text.secondary">
                Save on these great deals before their gone!
              </Typography>

              <Link href="/marketplace" passHref>
                <Typography
                  variant="subtitle1"
                  className={cx(styles.link, styles.view)}
                >
                  View All
                  <ChevronIcon fontSize="small" />
                </Typography>
              </Link>
            </div>
          )}
          <Swiper
            slidesPerPage={dealSlidesPerView}
            spaceBetween={8}
            roundSlides={!isMobile}
            isTopContainerHidden={!isMobile}
            title="Save on these great deals before they are gone!"
            titleColor="text.secondary"
            titleVariant="h6"
            className={styles.newDealsContainer}
            swiperProps={{
              onSwiper: setSwiperObj,
            }}
          >
            {deals.map((each) => (
              <Slider key={each.id}>
                <Item item={each} key={each.id} />
              </Slider>
            ))}
            {isMobile && (
              <Slider className={styles.viewAllSlider}>
                <Link href="/marketplace" passHref>
                  <Button variant="outlined" color="info">
                    View All
                  </Button>
                </Link>
              </Slider>
            )}
          </Swiper>
        </div>
      )}

      <div className={styles.row}>
        <div className={styles.title}>
          <Typography variant="h6" color="text.secondary">
            Share your experience and get rewarded!
          </Typography>
        </div>
        <TaskCard
          task={{
            data: {},
            title: "Reward Centre",
            body: isMobile
              ? ""
              : "Share your unique code with a friend and you’ll both get $100 towards any trip.",
            route: "gworld/rewards?tab=reward-center",
          }}
        />
      </div>

      <div className={styles.row}>
        <div className={styles.title}>
          <Typography variant="h6" color="text.secondary">
            Explore Global Roaming Blog...
          </Typography>
        </div>

        <div className={styles.blogCards}>
          <BlogCard
            backgroundUrl="https://dvna932ue33yp.cloudfront.net/blog-migration/hubfs/474541/239575857_a8d268d070_o.jpg"
            onClick={() => {
              openExternalURL(
                "http://blog.globalworkandtravel.com/6-reasons-you-need-travel-insurance-on-your-next-trip"
              );
            }}
            title={`Do I really need to buy travel insurance?`}
          />

          <BlogCard
            backgroundUrl="https://dvna932ue33yp.cloudfront.net/blog-migration/hubfs/474541/how-to-make-friends-abroad-6.jpeg"
            onClick={() => {
              openExternalURL(
                "https://blog.globalworkandtravel.com/how-to-make-new-friends-abroad/"
              );
            }}
            title={`How to make new friends abroad`}
          />
        </div>
      </div>
    </div>
  );
};
export default NoTasks;
